import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetStoriesQuery } from '../../../gql';
import { UserContext } from '../../UserContext';


export const StoryPicker = () => {
    const userContext = useContext(UserContext);
    const storyQuery = useGetStoriesQuery(undefined, { enabled: userContext.token != null, refetchOnMount: true });
    const navigate = useNavigate();


    const anonymousLogin = () => {
        let token = localStorage.getItem('anonymous-token');
        if (token == null) {
            token = crypto.randomUUID();
            localStorage.setItem('anonymous-token', token);
        }

        userContext.updateToken('anonymous', token);
    }

    return (
        <>
            <Stack alignItems='center' justifyContent='center' height='100%' spacing={6} mt={2}>
                <Paper sx={{ pb: 4, px: 4, pt: 4 }}>
                    <Typography variant='h4' textAlign='center' gutterBottom> {
                        userContext.token == null
                            ? 'Embark on an AI driven adventure where you are the hero!'
                            : 'Choose an adventure'}
                    </Typography>
                    <Stack alignItems='center' justifyContent='center' mt={8} spacing={2}>
                        {storyQuery.isFetching && <CircularProgress />}

                        {userContext.token == null &&
                            <Stack>
                                <Typography variant='body1' textAlign='center' gutterBottom>
                                    Sign in to save you progress
                                </Typography>
                                <Button variant='contained' fullWidth onClick={() => navigate('/login')}>
                                    Sign in
                                </Button>

                                <Typography variant='body1' mt={4} textAlign='center' gutterBottom>or try now!</Typography>
                                <Button variant='contained' fullWidth onClick={() => anonymousLogin()}>
                                    Begin My Adventure
                                </Button>
                            </Stack>
                        }

                        <Stack spacing={2}>
                            {userContext.token != null && !storyQuery.isFetching && [0, 1, 2].map((index) => {
                                const story = storyQuery.data?.story.at(index);

                                return story ? (
                                    <Button variant='contained' fullWidth sx={{ justifyContent: 'flex-start' }} key={story?.id} onClick={() => navigate(`/story/${story.id}`)}>
                                        {`Slot ${index + 1}: ${story?.name}`}
                                    </Button>)
                                    : (<Button variant='contained' fullWidth sx={{ justifyContent: 'flex-start' }} onClick={() => navigate("/createStory")} key={index}>
                                        {`Slot ${index + 1}: New Adventure`}
                                    </Button>)
                            }
                            )}
                        </Stack>

                    </Stack>
                </Paper>
            </Stack>
        </>
    )
};