import { Box, Card, CardActions, CardContent, Divider, List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';

export const ChapterSkeleton = () =>
{
    const theme = useTheme();
    const isTabletOrUp = useMediaQuery(theme.breakpoints.up('md'));

    const renderCardImage = () => (
            <Skeleton variant="rectangular" 
                sx={{ width: { xs: 'auto', md: '100%' }, 
                    height: { xs: '200px', md: 'auto' }}} />);
    
    return (
            <Card sx={{ display: 'flex', flexDirection:'column',}}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row'} }}>
                        {!isTabletOrUp && renderCardImage()} 
                        <CardContent  sx={{ flex: '1 1 auto', minWidth: { xs: '300px', md: '700px' }}}>
                            <List>
                                <ListItem alignItems="flex-start">
                                    <Stack width='100%'><Skeleton /> <Skeleton /> <Skeleton /></Stack>                        
                                </ListItem>
                                <Divider/> 
                                <ListItemButton alignItems="flex-start">
                                    <ListItemText primary="Option 1:" secondary={
                                         <Skeleton />
                                    } />                              
                                </ListItemButton>
                                <ListItemButton alignItems="flex-start">
                                    <ListItemText primary="Option 2:" secondary={
                                        <Skeleton />
                                    } />              
                                </ListItemButton>
                    
                            </List>
                        </CardContent>
                        {isTabletOrUp && renderCardImage()} 
                    
                    </Box>
                    <CardActions sx={{ 
                        backgroundColor: (theme) => theme.palette.primary.light,
                        justifyContent: 'center'
                    }}>
                    </CardActions>
                </Card>
    )
}