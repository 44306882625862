import { Box, Dialog, DialogContent, DialogTitle, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateStoryMutation } from '../../../gql';

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const CreateStoryPage = () => {

  const navigate = useNavigate();
  const createStoryMutation = useCreateStoryMutation({ onSuccess: (story) => { navigate(`/story/${story?.createStory.id}`) } });

  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    if (!createStoryMutation.isLoading && !createStoryMutation.isSuccess && !createStoryMutation.isError) {
      createStoryMutation.mutate({});
    }
  }, [createStoryMutation]);

  useEffect(() => {
    if (createStoryMutation.isLoading) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
        ((prevProgress >= 90 || !createStoryMutation.isLoading)
          ? 90
          : prevProgress + 10))
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [createStoryMutation.isLoading]);

  return (
    <Dialog open={true}>
      <DialogTitle>Creating Story...</DialogTitle>
      <DialogContent>
        <LinearProgressWithLabel value={progress} />
      </DialogContent>
    </Dialog>);
}