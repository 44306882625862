import React, { useEffect } from 'react';
import { ReactNode, useCallback, useState } from 'react';
import { googleLogout } from '@react-oauth/google';

type UserContextType = {
  provider: string | null | undefined,
  token: string | null | undefined,
  anonymousToken: string | null | undefined,
  updateToken: (provider: string | undefined, token: string | undefined) => void,
  logout: () => void

};

export const UserContext = React.createContext<UserContextType>({
  provider: '',
  token: undefined,
  anonymousToken: undefined,
  updateToken: (provider, token) => undefined,
  logout: () => undefined
});

export const UserContextProvider = (props: { children: ReactNode }) => {
  const [provider, setProvider] = useState<string | null | undefined>(localStorage.getItem('provider'));
  const [token, setToken] = useState<string | null | undefined>(localStorage.getItem('token'));
  const [anonymousToken, setAnonymousToken] = useState<string | null | undefined>(localStorage.getItem('anonymousToken'));

  useEffect(() => {
    if (!localStorage.getItem('anonymousToken')) {
      localStorage.setItem('anonymousToken', crypto.randomUUID());
    } else {
      setAnonymousToken(localStorage.getItem('anonymousToken'));
    }
  }, []);
  const updateToken = useCallback((provider: string | undefined, token: string | undefined) => {
    setProvider(provider);
    if (provider) {
      localStorage.setItem('provider', provider);
    } else {
      localStorage.removeItem('provider');
    }

    setToken(token);
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, []);

  const logout = useCallback(() => {
    if (provider === 'Google') {
      googleLogout();
    }

    updateToken(undefined, undefined);
  }, [provider, updateToken]);

  return (
    <UserContext.Provider value={{ provider, anonymousToken, token, updateToken, logout }}>
      {props.children}
    </UserContext.Provider>
  );
};