
import { CreateStoryPage } from './modules/Stories/views/CreateStoryPage';
import { LoginPage } from './modules/Stories/views/LoginPage';
import { StoryPage } from './modules/Stories/views/StoryPage';
import { StoryPicker } from './modules/Stories/views/StoryPicker';

const AppRoutes = [
  {
    path: "/",
    element: <StoryPicker />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "story/:storyId",
    element: <StoryPage />,
  },
  {
    path: "createStory",
    element: <CreateStoryPage />,
  }

];

export default AppRoutes;
