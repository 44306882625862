import { AppBar, Button, Container, Stack, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo512.png';
import { UserContext } from '../modules/UserContext';

export const Layout = (props: { children: ReactNode }) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <>
      <Stack sx={{ display: 'flex', minHeight: '100vh' }}>
        <AppBar position='static'>
          <Toolbar>
            <Box sx={{ cursor: 'pointer', }} onClick={() => navigate("/")}><img src={logo} width={50} height={50} alt='logo' /></Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {userContext.token != null && (<Button color="inherit" onClick={userContext.logout}>Logout</Button>)}
              {userContext.token == null && (<Button color="inherit" onClick={() => navigate('/login')} >Login</Button>)}
            </Box>
          </Toolbar>
        </AppBar>
        <Container sx={{ flexGrow: 1 }}>
          {props.children}
        </Container>
      </Stack>
    </>
  );

}
