export class ApiError extends Error {
  public readonly errorCode: number;

  constructor(errorCode: number, message?: string) {
    super(message);
    this.errorCode = errorCode;
  }
}

export class DomainError extends Error {
}

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): (() => Promise<TData>) => {
  return async () => {
    const apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://api.myendlessadventure.com/api/graphql/';
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options ?? {}),
        'Authorization': 'Bearer ' + localStorage.getItem('token') ?? '',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const result = await response.json();

    if (result.error?.extensions?.code === 'AUTH_NOT_AUTHENTICATED' || result.status === 401) {
      throw new ApiError(401, result.error?.message);
    }

    const resultQueryNames = Object.keys(result.data);
    for (const index in resultQueryNames) {
      if (result.data[resultQueryNames[index]]?.errors) {
        const error = result.data[resultQueryNames[index]].errors[0];
        throw new DomainError(error.message);
      }
    }

    return result.data;
  };
};