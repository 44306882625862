import { createContext, useEffect, useState } from 'react';

export const ScrollContext = createContext({ isScrolling: false});

export const ScrollContextProvider = (props: { children: React.ReactNode }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    function handleScroll() {
      clearTimeout(timeout);
      setIsScrolling(true);

      timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeout);
    };
  }, []);

  return (
    
    <ScrollContext.Provider value={{ isScrolling }} >
      {props.children}
    </ScrollContext.Provider>
  );
}