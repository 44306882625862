import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetChapterIdsQuery, useGetStoryQuery } from '../../../gql';
import { ChapterCard } from '../components/Chapter';
import { ScrollContextProvider } from '../components/ScrollContext';

export const StoryPage = () => {
    const storyId = useParams<{ storyId: string }>().storyId;
    const storyQuery = useGetStoryQuery({ id: storyId! });
    const chatpersQuery = useGetChapterIdsQuery({ id: storyId! });

    return (
        <>
            <ScrollContextProvider>
                <Stack alignItems='center' justifyContent='center' minHeight={'100vh'} spacing={10} paddingBottom={'25vh'}>
                    <Typography gutterBottom variant="h5" component="div" textAlign='center' >
                        {storyQuery.data?.story?.at(0)?.name}
                    </Typography>

                    {chatpersQuery.data?.chapters.map((chapter, index) => (
                        <ChapterCard key={chapter?.id} chapterId={chapter?.id} isCurrentChapter={index === chatpersQuery.data.chapters.length - 1} />
                    ))}

                </Stack>
            </ScrollContextProvider>
        </>
    )
};