import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useContext, useMemo } from 'react';

import { ApiError } from './gql/graphql-fetcher';
import { UserContext } from './modules/UserContext';

export const QueryProvider = (props: { children: React.ReactNode }) => {
  const userContext = useContext(UserContext);
  const client = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        onError: (error) => {
          if (error instanceof ApiError && error.errorCode === 401) {
            userContext.updateToken(undefined, undefined);
          }
        }
      },
      mutations: {

        retry: false
      }
    }
  }), [userContext]);

  return (
    <QueryClientProvider client={client}>
      <ReactQueryDevtools initialIsOpen={false} />
      {props.children}
    </QueryClientProvider>
  );
};