import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { QueryProvider } from './QueryProvider';
import { Layout } from './components/Layout';
import { UserContextProvider } from './modules/UserContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2c3e50',
      light: '#607d8b',
      dark: '#1b2a38',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff5252',
      light: '#ff867c',
      dark: '#c50e29',
      contrastText: '#fff',
    },
    background: {
      paper: '#212121',
      default: '#121212',
    },
    text: {
      primary: '#fff',
      secondary: '#b0bec5',
    },
  }
}));



export const App = () => {
  return (
    <>
      <UserContextProvider>
        <QueryProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Layout>
              <ToastContainer />
              <Routes>
                {AppRoutes.map((route, index) => {
                  const { element, ...rest } = route;
                  return <Route key={index} {...rest} element={element} />;
                })}
              </Routes>
            </Layout>

          </ThemeProvider>
        </QueryProvider>
      </UserContextProvider>
    </>
  );
}
