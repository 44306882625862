import { Button, Paper, Stack, Typography } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../UserContext';

export const LoginPage = () => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const anonymousLogin = () => {
        let token = localStorage.getItem('anonymous-token');
        if (token == null) {
            token = crypto.randomUUID();
            localStorage.setItem('anonymous-token', token);
        }

        userContext.updateToken('anonymous', token);
        navigate(-1);
    }
    return (
        <>
            <Stack alignItems='center' justifyContent='center' minHeight={'100vh'} spacing={10}>
                <Paper sx={{ pb: 20, px: 20, pt: 10 }}>
                    <Typography variant='h4' textAlign='center' gutterBottom>Sign in</Typography>
                    <Typography variant='h5' textAlign='center' gutterBottom>Sign in to save you progress</Typography>
                    <Stack alignItems='center' justifyContent='center' mt={8} spacing={2}>
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                userContext.updateToken('Google', credentialResponse.credential);
                                navigate(-1);
                            }}
                            onError={() => {
                                userContext.updateToken(undefined, undefined);
                                navigate(-1);
                            }}
                        />
                        <Typography variant='body1' mt={4} textAlign='center' gutterBottom>or try now!</Typography>
                        <Button variant='contained' fullWidth onClick={() => anonymousLogin()}>
                            Begin My Adventure
                        </Button>
                    </Stack>
                </Paper>
            </Stack>
        </>
    );
}