import { Avatar, Box, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, List, ListItem, ListItemButton, ListItemText, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { GetChapterByIdQuery, useGetChapterByIdQuery, useSelectChapterDecisionMutation } from '../../../gql';
import { ChapterSkeleton } from './ChapterSkeleton';
import { ScrollContext } from './ScrollContext';
import { toast } from 'react-toastify';

export const ChapterCard = (props: { chapterId: number | undefined, isCurrentChapter: boolean }) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const scrollToRef = useRef<HTMLDivElement>(null);
    const nextChapterRef = useRef<HTMLDivElement>(null);
    const { data, isFetched } = useGetChapterByIdQuery({ chapterId: props.chapterId! }, { enabled: isEnabled });
    const chapter = data?.chapters?.at(0);
    const { ref, inView } = useInView();
    const { isScrolling } = useContext(ScrollContext);


    useEffect(() => {
        if (!isEnabled && ((inView && !isScrolling && props.chapterId != null) || props.isCurrentChapter)) {
            setIsEnabled(true);
        }
    }, [isEnabled, inView, isScrolling, props.chapterId, props.isCurrentChapter])

    const queryClient = useQueryClient();

    const generateNextChapterMutation = useSelectChapterDecisionMutation({
        onMutate: (variables) => {
            queryClient.setQueryData<GetChapterByIdQuery>(['getChapterById', { chapterId: props.chapterId! }],
                {
                    ...data?.chapters,
                    chapters: [{ ...data?.chapters?.at(0)!, selectedOption: variables.input.selectedOptionNumber }]
                });
            setTimeout(() => nextChapterRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 300);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['getStory']);
            queryClient.invalidateQueries(['getChapterIds']);
        },
        onError: () => {
            queryClient.setQueryData<GetChapterByIdQuery>(['getChapterById', { chapterId: props.chapterId! }],
                {
                    ...data?.chapters,
                    chapters: [{ ...data?.chapters?.at(0)!, selectedOption: undefined }]
                });
            toast('Failed to generate next chapter. Please try again later.', { type: 'error' });

        }
    });


    const theme = useTheme();
    const isTabletOrUp = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (props.isCurrentChapter) {
            setTimeout(() => scrollToRef.current?.scrollIntoView({ behavior: 'auto', block: 'start' }), 500);
        }
    }, [props.isCurrentChapter])

    const renderCardImage = () => (
        <CardMedia
            component='img'
            sx={{ width: { xs: 'auto', md: '300px' }, height: { xs: '200px', md: 'auto' } }}
            image={chapter?.imageUrl ?? undefined}
        />);

    return (<div ref={scrollToRef}>
        <div ref={ref}>

            <Stack spacing={10}>
                <Box marginX={5}>
                    {!isFetched ? (<ChapterSkeleton />) :
                        (<Card key={chapter?.id} sx={{ display: 'flex', flexDirection: 'column', }}>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                                {!isTabletOrUp && renderCardImage()}
                                <CardContent sx={{ flex: '1 1 auto', minWidth: { xs: '300px', md: '700px' } }}>
                                    <List>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText primary={chapter?.text} />
                                        </ListItem>
                                        <Divider />
                                        <ListItemButton alignItems="flex-start" onClick={() => chapter?.storyId && chapter?.selectedOption == null && generateNextChapterMutation.mutate({ input: { storyId: chapter.storyId, selectedOptionNumber: 1 } })}>
                                            <ListItemText primary="Option 1:" secondary={
                                                chapter?.option1
                                            } />
                                        </ListItemButton>
                                        <ListItemButton alignItems="flex-start" onClick={() => chapter?.storyId && chapter?.selectedOption == null && generateNextChapterMutation.mutate({ input: { storyId: chapter.storyId, selectedOptionNumber: 2 } })}>
                                            <ListItemText primary="Option 2:" secondary={
                                                chapter?.option2
                                            } />
                                        </ListItemButton>

                                    </List>
                                </CardContent>
                                {isTabletOrUp && renderCardImage()}

                            </Box>
                            <CardActions sx={{
                                backgroundColor: (theme) => theme.palette.primary.light,
                                justifyContent: 'center'
                            }}>
                                {chapter && <audio controls autoPlay={chapter?.selectedOption == null}>
                                    <source src={chapter?.audioUrl} type="audio/ogg" />
                                    Your browser does not support the audio element.
                                </audio>}
                            </CardActions>
                        </Card>)}
                </Box>
                {chapter?.selectedOption && (
                    <Card sx={{ marginLeft: 2 }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: (theme) => theme.palette.info.dark }} aria-label="recipe">
                                    {chapter.selectedOption}
                                </Avatar>
                            }
                            title={chapter.selectedOption === 1 ? chapter.option1 : chapter.option2}
                        />
                    </Card>)}
                {generateNextChapterMutation.isLoading && (<div ref={nextChapterRef}><ChapterSkeleton /></div>)}
            </Stack>
        </div>
    </div>);
}