import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchData } from 'gql/graphql-fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Chapter = {
  __typename?: 'Chapter';
  audioUrl: Scalars['String'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  option1: Scalars['String'];
  option2: Scalars['String'];
  selectedOption?: Maybe<Scalars['Int']>;
  storyId: Scalars['String'];
  text: Scalars['String'];
};

export type ChapterFilterInput = {
  and?: InputMaybe<Array<ChapterFilterInput>>;
  audioUrl?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  imageUrl?: InputMaybe<StringOperationFilterInput>;
  option1?: InputMaybe<StringOperationFilterInput>;
  option2?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ChapterFilterInput>>;
  selectedOption?: InputMaybe<IntOperationFilterInput>;
  storyId?: InputMaybe<StringOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createStory: Story;
  selectChapterDecision: Chapter;
};


export type MutationSelectChapterDecisionArgs = {
  input: SelectChapterDecisionInput;
};

export type Query = {
  __typename?: 'Query';
  chapters: Array<Chapter>;
  story: Array<Story>;
};


export type QueryChaptersArgs = {
  where?: InputMaybe<ChapterFilterInput>;
};


export type QueryStoryArgs = {
  where?: InputMaybe<StoryFilterInput>;
};

export type SelectChapterDecisionInput = {
  selectedOptionNumber: Scalars['Int'];
  storyId: Scalars['String'];
};

export type Story = {
  __typename?: 'Story';
  chapters: Array<Chapter>;
  createdOn: Scalars['DateTime'];
  genre?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type StoryFilterInput = {
  and?: InputMaybe<Array<StoryFilterInput>>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  genre?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StoryFilterInput>>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type ValidationAttribute = {
  __typename?: 'ValidationAttribute';
  errorMessage?: Maybe<Scalars['String']>;
  errorMessageResourceName?: Maybe<Scalars['String']>;
  formatErrorMessage: Scalars['String'];
  isDefaultAttribute: Scalars['Boolean'];
  requiresValidationContext: Scalars['Boolean'];
};


export type ValidationAttributeFormatErrorMessageArgs = {
  name: Scalars['String'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  message: Scalars['String'];
  validationAttribute?: Maybe<ValidationAttribute>;
  validationResult: ValidationResult;
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  errorMessage?: Maybe<Scalars['String']>;
  memberNames: Array<Scalars['String']>;
};

export type CreateStoryMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStoryMutation = { __typename?: 'Mutation', createStory: { __typename?: 'Story', id: string, genre?: string | null, name: string } };

export type SelectChapterDecisionMutationVariables = Exact<{
  input: SelectChapterDecisionInput;
}>;


export type SelectChapterDecisionMutation = { __typename?: 'Mutation', selectChapterDecision: { __typename?: 'Chapter', id: number } };

export type GetChapterByIdQueryVariables = Exact<{
  chapterId: Scalars['Int'];
}>;


export type GetChapterByIdQuery = { __typename?: 'Query', chapters: Array<{ __typename?: 'Chapter', id: number, text: string, option1: string, option2: string, imageUrl: string, audioUrl: string, storyId: string, selectedOption?: number | null }> };

export type GetChapterIdsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetChapterIdsQuery = { __typename?: 'Query', chapters: Array<{ __typename?: 'Chapter', id: number }> };

export type GetStoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoriesQuery = { __typename?: 'Query', story: Array<{ __typename?: 'Story', id: string, name: string, genre?: string | null }> };

export type GetStoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetStoryQuery = { __typename?: 'Query', story: Array<{ __typename?: 'Story', id: string, name: string }> };


export const CreateStoryDocument = `
    mutation createStory {
  createStory {
    id
    genre
    name
  }
}
    `;
export const useCreateStoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateStoryMutation, TError, CreateStoryMutationVariables, TContext>) =>
    useMutation<CreateStoryMutation, TError, CreateStoryMutationVariables, TContext>(
      ['createStory'],
      (variables?: CreateStoryMutationVariables) => fetchData<CreateStoryMutation, CreateStoryMutationVariables>(CreateStoryDocument, variables)(),
      options
    );
export const SelectChapterDecisionDocument = `
    mutation selectChapterDecision($input: SelectChapterDecisionInput!) {
  selectChapterDecision(input: $input) {
    id
  }
}
    `;
export const useSelectChapterDecisionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SelectChapterDecisionMutation, TError, SelectChapterDecisionMutationVariables, TContext>) =>
    useMutation<SelectChapterDecisionMutation, TError, SelectChapterDecisionMutationVariables, TContext>(
      ['selectChapterDecision'],
      (variables?: SelectChapterDecisionMutationVariables) => fetchData<SelectChapterDecisionMutation, SelectChapterDecisionMutationVariables>(SelectChapterDecisionDocument, variables)(),
      options
    );
export const GetChapterByIdDocument = `
    query getChapterById($chapterId: Int!) {
  chapters(where: {id: {eq: $chapterId}}) {
    id
    text
    option1
    option2
    imageUrl
    audioUrl
    storyId
    selectedOption
  }
}
    `;
export const useGetChapterByIdQuery = <
      TData = GetChapterByIdQuery,
      TError = unknown
    >(
      variables: GetChapterByIdQueryVariables,
      options?: UseQueryOptions<GetChapterByIdQuery, TError, TData>
    ) =>
    useQuery<GetChapterByIdQuery, TError, TData>(
      ['getChapterById', variables],
      fetchData<GetChapterByIdQuery, GetChapterByIdQueryVariables>(GetChapterByIdDocument, variables),
      options
    );
export const GetChapterIdsDocument = `
    query getChapterIds($id: String!) {
  chapters(where: {storyId: {eq: $id}}) {
    id
  }
}
    `;
export const useGetChapterIdsQuery = <
      TData = GetChapterIdsQuery,
      TError = unknown
    >(
      variables: GetChapterIdsQueryVariables,
      options?: UseQueryOptions<GetChapterIdsQuery, TError, TData>
    ) =>
    useQuery<GetChapterIdsQuery, TError, TData>(
      ['getChapterIds', variables],
      fetchData<GetChapterIdsQuery, GetChapterIdsQueryVariables>(GetChapterIdsDocument, variables),
      options
    );
export const GetStoriesDocument = `
    query getStories {
  story {
    id
    name
    genre
  }
}
    `;
export const useGetStoriesQuery = <
      TData = GetStoriesQuery,
      TError = unknown
    >(
      variables?: GetStoriesQueryVariables,
      options?: UseQueryOptions<GetStoriesQuery, TError, TData>
    ) =>
    useQuery<GetStoriesQuery, TError, TData>(
      variables === undefined ? ['getStories'] : ['getStories', variables],
      fetchData<GetStoriesQuery, GetStoriesQueryVariables>(GetStoriesDocument, variables),
      options
    );
export const GetStoryDocument = `
    query getStory($id: String!) {
  story(where: {id: {eq: $id}}) {
    id
    name
  }
}
    `;
export const useGetStoryQuery = <
      TData = GetStoryQuery,
      TError = unknown
    >(
      variables: GetStoryQueryVariables,
      options?: UseQueryOptions<GetStoryQuery, TError, TData>
    ) =>
    useQuery<GetStoryQuery, TError, TData>(
      ['getStory', variables],
      fetchData<GetStoryQuery, GetStoryQueryVariables>(GetStoryDocument, variables),
      options
    );